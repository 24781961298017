<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :module_name="page.name" :current="record.name"/>
    <v-container>
      <v-row>
        <v-col>
          <base-tab-panel
            :module_name="page.name"
            :record="record"
            @change="save"
            @archive="archive"
            :loading="loading"
            :model="page.model">
            <template v-slot:main_fields>
              <v-text-field
                  :class="'ma-0 pt-'+$store.getters.lineheight"
                  :label="$store.getters.translate('name')"
                  v-model="record.name">
                <template #label>{{ $store.getters.translate('name') }}<span class="red--text"><strong>*</strong></span></template>
              </v-text-field>
              <v-select
                  :class="'ma-0 pt-'+$store.getters.lineheight"
                  :label="$store.getters.translate('model_type')"
                  v-model="record.model_type"
                  validate-on-blur
                  :items="$lodash.sortBy(models, 'text')">
                <template #label>{{ $store.getters.translate('model_type') }}<span class="red--text"><strong>*</strong></span></template>
              </v-select>
              <v-select
                  :class="'ma-0 pt-'+$store.getters.lineheight"
                  :label="$store.getters.translate('linked_model')"
                  v-model="record.linked_model"
                  validate-on-blur
                  :items="$lodash.sortBy(models, 'text')"
                  clearable/>
              <v-select
                  :class="'ma-0 pt-'+$store.getters.lineheight"
                  :label="$store.getters.translate('event')"
                  v-model="record.event"
                  validate-on-blur
                  :items="getEvents">
                <template #label>{{ $store.getters.translate('event') }}<span class="red--text"><strong>*</strong></span></template>
              </v-select>
              <base-log-field :model_type="$models[page.name]" :model_id="record.id"/>
              <v-checkbox
                  :class="'ma-0 pt-'+$store.getters.lineheight"
                  inset
                  color="success"
                  v-model="record.active"
                  :label="$store.getters.translate('active')"/>
            </template>
            <template v-slot:module_fields>
              <v-textarea
                  :class="'ma-0 pt-'+$store.getters.lineheight"
                  :label="$store.getters.translate('description')"
                  v-model="record.description"
                  validate-on-blur
                  :disabled="loading"/>
            </template>
          </base-tab-panel>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <!-- TRIGGERS -->
          <v-row>
            <v-col>
              <v-toolbar dark color="primary" class="elevation-1" dense flat>
                {{ $store.getters.translate("triggers") }}
                <v-spacer/>
                <v-btn text small @click="createTrigger">
                  <v-icon> mdi-plus </v-icon>
                </v-btn>
              </v-toolbar>
            </v-col>
          </v-row>
          <v-btn v-if="record.triggers.length === 0" @click="createTrigger" block text color="primary" class="ma-10">
            {{ $store.getters.translate("create_trigger") }}
          </v-btn>
          <v-row>
            <v-col>
              <v-expansion-panels>
                <v-expansion-panel v-for="(trigger, index) in record.triggers" :key="index">
                  <v-expansion-panel-header>
                    <v-toolbar class="no-left-padding" dense flat>
                      <v-icon left class="mr-2">{{ getTriggerIcon(trigger.type) }}</v-icon>
                      <v-toolbar-title class="ma-0 pa-0">
                        {{ $store.getters.translate(trigger.name) }}:
                        <v-chip v-if="trigger.to" label class="ml-5">{{ $store.getters.translate(trigger.to) }}</v-chip>
                        <v-chip v-if="trigger.from" label class="ml-5">{{ $store.getters.translate(trigger.from) }}</v-chip>
                      </v-toolbar-title>
                    </v-toolbar>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-container>
                      <v-card flat class="mt-9" :disabled="loading">
                        <v-toolbar dense flat>
                          <v-select
                              :class="'ma-0 pt-'+$store.getters.lineheight"
                              :label="$store.getters.translate('event')"
                              v-model="trigger.type"
                              validate-on-blur
                              :items="getTriggers"
                              @change="saveTrigger(trigger)"/>
                          <v-spacer/>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                  @click="deleteTrigger(trigger)"
                                  v-bind="attrs"
                                  v-on="on"
                                  text
                                  small
                                  class="mr-2"
                                  color="secondary">
                                <v-icon color="error">mdi-trash-can</v-icon>
                              </v-btn>
                            </template>
                            <span>{{ $store.getters.translate("delete") }}</span>
                          </v-tooltip>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                  @click="saveTrigger(trigger)"
                                  v-bind="attrs"
                                  v-on="on"
                                  text
                                  small
                                  color="primary">
                                <v-icon>mdi-content-save</v-icon>
                              </v-btn>
                            </template>
                            <span>{{ $store.getters.translate("save") }}</span>
                          </v-tooltip>
                        </v-toolbar>
                        <v-card-text>
                          <v-row v-if="trigger.type !== 'certificate_not_exist'">
                            <v-col>
                              <v-select
                                  :class="'ma-0 pt-'+$store.getters.lineheight"
                                  :label="$store.getters.translate('model')"
                                  v-model="trigger.model_type"
                                  :items="modelOptions"
                                  item-value="value"
                                  item-text="text"
                                  @change="getFields(trigger.model_type, trigger.type)"/>
                            </v-col>
                          </v-row>
                          <v-row v-if="trigger.type !== 'added_tag' && trigger.type !== 'removed_tag' && trigger.type !== 'tag_exist' && trigger.type !== 'certificate_not_exist'">
                            <v-col>
                              <v-select
                                  :class="'ma-0 pt-'+$store.getters.lineheight"
                                  :label="$store.getters.translate('field')"
                                  v-model="trigger.subject"
                                  :items="fields"
                                  item-value="value"
                                  item-text="text"/>
                            </v-col>
                          </v-row>
                          <v-row v-if="trigger.type !== 'added_tag' && trigger.type !== 'removed_tag' && trigger.type !== 'tag_exist' && trigger.type !== 'certificate_not_exist'">
                            <v-col>
                              <!--CHECKBOXES/BOOLEAN VALUES-->
                              <template v-if="getField(trigger.subject, trigger.type) && getField(trigger.subject, trigger.type).field_type === 'boolean'">
                                <v-checkbox v-model="trigger.to" :value="trigger.to"/>
                              </template>
                              <!--DATES-->
                              <template v-else-if="getField(trigger.subject, trigger.type) && getField(trigger.subject, trigger.type).field_type === 'date'">
                                <v-text-field :label="$store.getters.translate('amount_of_days')" v-model="trigger.to"/>
                              </template>
                              <!--DROPDOWNS-->
                              <template v-else-if="getField(trigger.subject, trigger.type) && (getField(trigger.subject, trigger.type).field_type === 'select_single' || getField(trigger.subject, trigger.type).field_type === 'select_multiple')">
                                <v-select
                                    :class="'ma-0 pt-'+$store.getters.lineheight"
                                    :label="$store.getters.translate('value')"
                                    v-model="trigger.to"
                                    :items="getItems(trigger.subject)"
                                    item-value="value"
                                    item-text="text"/>
                              </template>
                              <!--TEXT FIELDS-->
                              <template v-else>
                                <v-text-field
                                    :class="'ma-0 pt-'+$store.getters.lineheight"
                                    :label="$store.getters.translate('value')"
                                    v-model="trigger.to"/>
                              </template>
                              <template v-if="trigger.type === 'before_date' || trigger.type === 'after_date'">
                                <v-text-field
                                    :class="'ma-0 pt-'+$store.getters.lineheight"
                                    :label="$store.getters.translate('amount_of_repeats')"
                                    v-model="trigger.amount_of_repeats"/>
                              </template>
                            </v-col>
                          </v-row>
                          <v-row v-if="trigger.type === 'added_tag' || trigger.type === 'removed_tag' || trigger.type === 'tag_exist'">
                            <v-col>
                              <base-tags v-model="trigger.to" :module_name="modelRelation" :single="true"/>
                            </v-col>
                            <p class="mt-5">{{ $store.getters.translate('or') }}</p>
                            <v-col>
                              <base-tags v-model="trigger.from" :module_name="modelRelation" :single="true"/>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-container>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
          <!-- ACTIONS -->
          <v-row>
            <v-col>
              <v-toolbar dark color="primary" class="elevation-1" dense flat>
                {{ $store.getters.translate("actions") }}
                <v-spacer/>
                <v-btn text small @click="createAction">
                  <v-icon> mdi-plus </v-icon>
                </v-btn>
              </v-toolbar>
            </v-col>
          </v-row>
          <v-btn v-if="record.actions.length === 0" @click="createAction" block text color="primary" class="ma-10">
            {{ $store.getters.translate("create_action") }}
          </v-btn>
          <v-row>
            <v-col>
              <v-expansion-panels>
                <v-expansion-panel v-for="action in record.actions" :key="action.id">
                  <v-expansion-panel-header>
                    <v-toolbar class="no-left-padding" dense flat>
                      <v-icon left class="mr-2">{{ getActionIcon(action.type) }}</v-icon>
                      <v-toolbar-title class="ma-0 pa-0">
                        {{ $store.getters.translate(action.name) }}:
                        <v-chip v-html="determineSubject(action)" label class="ml-5 pt-4"/>
                      </v-toolbar-title>
                    </v-toolbar>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-container>
                      <v-card flat :disabled="loading">
                        <v-toolbar dense flat>
                          <v-select
                            color="success"
                            v-model="action.type"
                            validate-on-blur
                            :items="$lodash.sortBy(action_types, 'text')"/>
                          <v-checkbox
                              class="ml-5"
                              :label="$store.getters.translate('repeat')"
                              v-model="action.parameters.repeat"/>
                          <v-spacer/>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-bind="attrs"
                                v-on="on"
                                text
                                small
                                @click="deleteAction(action)"
                                class="mr-2"
                                color="secondary">
                                <v-icon color="error">mdi-trash-can</v-icon>
                              </v-btn>
                            </template>
                            <span>{{ $store.getters.translate("delete") }}</span>
                          </v-tooltip>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                @click="saveAction(action)"
                                v-bind="attrs"
                                v-on="on"
                                text
                                small
                                color="primary">
                                <v-icon>mdi-content-save</v-icon>
                              </v-btn>
                            </template>
                            <span>{{ $store.getters.translate("save") }}</span>
                          </v-tooltip>
                        </v-toolbar>
                        <v-card-text class="pa-9">
                          <base-link
                            v-if="action.type === 'notify_users'"
                            @change="addModels"
                            @click="active_action = action"
                            module_name="users"
                            :label="$store.getters.translate('choose_users')"
                            :excluded_ids="$lodash.map(active_action.models, 'id')"/>
                          <base-link
                            v-if="action.type === 'notify_roles'"
                            @change="addModels"
                            @click="active_action = action"
                            module_name="roles"
                            :label="$store.getters.translate('choose_roles')"
                            :excluded_ids="$lodash.map(active_action.models, 'id')"/>
                          <v-combobox
                            v-if="action.models.length > 0 && (action.type === 'notify_roles' || action.type === 'notify_users')"
                            class="mt-5"
                            clearable
                            v-model="action.models"
                            chips
                            deletable-chips
                            small-chips
                            multiple
                            item-value="id"
                            item-text="name"/>
                          <v-checkbox
                              v-if="action.type === 'notify_model'"
                              :label="$store.getters.translate('notify_related_contacts')"
                              v-model="action.parameters.notify_contacts"/>
                          <v-checkbox
                              v-if="action.type === 'notify_roles' || action.type === 'notify_users' || action.type === 'notify_current_user'"
                              :label="$store.getters.translate('send_email')"
                              v-model="action.parameters.send_email"/>
                          <v-text-field
                              v-if="action.type === 'create_note'"
                              :label="$store.getters.translate('title')"
                              v-model="action.title"
                              counter/>
                          <v-tabs v-if="action.type !== 'attach_tag' && action.type !== 'remove_tag' && action.type !== 'change_status' && action.type !== 'create_form'">
                            <v-tab>{{ $store.getters.translate("message_nl") }}</v-tab>
                            <v-tab>{{ $store.getters.translate("message_en") }}</v-tab>
                            <v-tab-item>
                              <edit-message v-model="action.message_nl" :model_type="record.model_type"/>
                            </v-tab-item>
                            <v-tab-item>
                              <edit-message v-model="action.message_en" :model_type="record.model_type"/>
                            </v-tab-item>
                          </v-tabs>
                          <v-select
                              v-if="action.type === 'change_status'"
                              :label="$store.getters.translate('change_status')"
                              v-model="action.parameters.change_status"
                              :items="getItems('status')"
                              item-value="value"
                              item-text="text"/>
                          <template v-if="action.type === 'create_note' || action.type === 'attach_tag' || action.type === 'remove_tag' || action.type === 'notify_model'">
                            <span v-if="action.type === 'remove_tag'">{{ $store.getters.translate("remove_from") }}</span>
                            <span v-else-if="action.type === 'notify_model'">{{ $store.getters.translate("send_to") }}</span>
                            <span v-else>{{ $store.getters.translate("attach_to") }}</span>
                            <v-treeview
                              color="primary"
                              selectable
                              selection-type="independent"
                              v-model="action.parameters.attach_to"
                              :items="relatedModels"
                              :key="key_1"
                              open-all/>
                            <v-treeview
                              v-if="record.linked_model && record.linked_model !== 'Contact' && record.linked_model !== 'Employee' && record.linked_model !== 'Assessor'"
                              color="primary"
                              selectable
                              selection-type="independent"
                              v-model="action.parameters.linked_model_attach_to"
                              :items="relatedModelsLinked"
                              :key="'linked_model' + key_2"
                              open-all/>
                            <v-checkbox
                                :label="$store.getters.translate('only_latest_record')"
                                v-model="action.parameters.only_latest_record"/>
                          </template>
                          <template v-if="action.type === 'create_note'">
                            <v-checkbox
                                :label="$store.getters.translate('new_note')"
                                v-model="action.parameters.new_note"/>
                            <v-checkbox
                                :label="$store.getters.translate('visible_candidate')"
                                v-model="action.parameters.visible_candidate"/>
                            <v-checkbox
                                v-if="action.parameters.visible_candidate"
                                :label="$store.getters.translate('editable_candidate')"
                                v-model="action.parameters.editable_candidate"
                                class="ml-5"/>
                            <v-checkbox
                                :label="$store.getters.translate('visible_contact')"
                                v-model="action.parameters.visible_contact"/>
                            <v-checkbox
                                v-if="action.parameters.visible_contact"
                                :label="$store.getters.translate('editable_contact')"
                                v-model="action.parameters.editable_contact"
                                class="ml-5"/>
                            <v-checkbox
                                :label="$store.getters.translate('visible_assessor')"
                                v-model="action.parameters.visible_assessor"/>
                            <v-checkbox
                                v-if="action.parameters.visible_assessor"
                                :label="$store.getters.translate('editable_assessor')"
                                v-model="action.parameters.editable_assessor"
                                class="ml-5"/>
                            <v-checkbox
                                :label="$store.getters.translate('is_request')"
                                v-model="action.parameters.is_request"/>
                            <v-checkbox
                                v-if="action.parameters.is_request"
                                :label="$store.getters.translate('request_fulfilled')"
                                v-model="action.parameters.request_fulfilled"/>
                            <v-checkbox
                                :label="$store.getters.translate('generate_document')"
                                v-model="action.parameters.generate_document"/>
                          </template>
                          <template v-if="(action.type === 'create_note' && action.parameters.generate_document) || action.type === 'create_form'">
                            <v-select
                              :label="$store.getters.translate('template')"
                              v-model="action.parameters.template_id"
                              validate-on-blur
                              :items="$lodash.sortBy(templates, 'name')"
                              item-value="id"
                              item-text="name"/>
                            <v-text-field v-if="action.type === 'create_note' && action.parameters.generate_document"
                              :label="$store.getters.translate('document_name')"
                              v-model="action.parameters.document_name"
                              counter/>
                          </template>
                          <template v-if="action.type === 'create_note'">
                            <v-autocomplete
                                :label="$store.getters.translate('type')"
                                v-model="action.parameters.document_type"
                                :items="getKindsForSelect"/>
                            <base-row
                              class="mt-3"
                              v-for="file in action.files"
                              :key="file.id"
                              :label="$store.getters.translate('file')"
                              :value="file.name"
                              @click="preview(file)"
                              :link="true"
                              :hide_copy="true"
                              :deletable="true"
                              @delete="deleteFile(file)"/>
                            <v-row>
                              <v-col>
                                <v-file-input
                                  class="mt-3"
                                  :label="$store.getters.translate('add_files')"
                                  show-size
                                  v-model="action.new_files"
                                  multiple
                                  @change="saveFiles(action)"/>
                              </v-col>
                            </v-row>
                          </template>
                          <base-tags
                              v-if="action.type === 'remove_tag' || action.type === 'attach_tag'"
                              v-model="action.message_nl"
                              :module_name="modelRelation"
                              :single="true"/>
                          <base-tags
                              v-else-if="action.type === 'create_note'"
                              v-model="action.parameters.tags"
                              module_name="notes"
                              :single="false"/>
                        </v-card-text>
                      </v-card>
                    </v-container>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <document-edit modal_name="document_edit" ref="document_edit"/>
  </v-container>
</template>

<script>
import helpFunctions from "../../plugins/helpFunctions.js";
import related_models_tree from "../../related_models_tree";
import models from "./models";
import BaseTabPanel from "../../components/commonComponents/BaseTabPanel";
import EditMessage from "./fields/Edit";
import BaseBreadcrumb from "../../components/commonComponents/BaseBreadcrumb";
import BaseLink from "../../components/commonComponents/BaseLink";
import DocumentEdit from "../documents/Edit";

export default {
  components: {
    DocumentEdit,
    BaseLink,
    BaseTabPanel,
    EditMessage,
    BaseBreadcrumb,
  },
  data() {
    return {
      page: {
        title: this.$store.getters.translate("workflows"),
        name: "workflows",
        model: "workflow",
      },
      record: {
        name: "",
        model_type: "",
        triggers: [],
        actions: [],
      },
      loading: false,
      active_action: {},
      active_trigger: {},
      fields: [],
      default_trigger: {},
      default_action: {},
      trigger_types: [],
      action_types: [],
      notes_kinds: {},
      countries: [],
      nationalities: [],
      tags: [],
      templates: [],
      key_1: 0,
      key_2: 0,
    };
  },
  created() {
    this.default_trigger = helpFunctions.default_trigger;
    this.default_action = helpFunctions.default_action;
    this.default_action.workflow_id = this.$route.params.id;
    this.default_action.parameters.tree = this.relatedModels;
    this.trigger_types = helpFunctions.trigger_types;
    this.action_types = helpFunctions.action_types;
    this.notes_kinds = helpFunctions.notes_kinds;
    this.load();
    this.getCountryNationality();
  },
  methods: {
    load() {
      this.loading = true;
      this.$http
          .get(this.$store.getters.appUrl + "v2/" + this.page.name + "/" + this.$route.params.id)
          .then((response) => {
            this.record = response.data;
            this.key_1 = this.key_1 + 1;
            this.key_2 = this.key_2 + 1;
            this.loading = false;
            if(this.record.triggers && this.record.triggers.length > 0) {
              this.getFields(this.record.triggers[0].model_type, this.record.triggers[0].type);
            }
            else {
              this.getFields(this.record.model_type, 'field_changes');
            }
          })
          .catch((error) => {
            this.$toasted.error(error);
            this.loading = false;
          });
    },
    save() {
      if(!this.loading) {
        let usual_fields_required = [];
        usual_fields_required['name'] = true;
        usual_fields_required['model_type'] = true;
        usual_fields_required['event'] = true;
        let error = false;
        for (let key in usual_fields_required) {
          if (usual_fields_required[key] && (!this.record[key] || this.record[key] === '')) {
            this.$toasted.error(this.$store.getters.translate("required_field") + ": " + this.$store.getters.translate(key));
            error = true;
          }
        }
        if (!error) {
          this.loading = true;
          this.$http
              .patch(this.$store.getters.appUrl + "v2/" + this.page.name + "/" + this.record.id, this.record)
              .then((response) => {
                this.loading = false;
                if (response.status == 200) {
                  this.$toasted.success(this.$store.getters.translate("successfully_saved"));
                } else {
                  var errorMessage = response.data.message;
                  var errors = Object.keys(response.data.message);
                  this.$toasted.error([errorMessage[errors[0]]]);
                }
              })
              .catch((error) => {
                this.$toasted.error(error);
                this.loading = false;
              });
        }
      }
    },
    archive() {
      this.$confirm(this.$store.getters.translate("are_you_sure?")).then((res) => {
          if (res) {
            this.loading = true;
            this.$http
                .delete(this.$store.getters.appUrl + "v2/" + this.page.name + "/" + this.record.id)
                .then(() => {
                  this.$router.push("/" + this.page.name);
                  this.loading = false;
                  this.$toasted.success(this.$store.getters.translate("successfully_deleted"));
                })
                .catch((error) => {
                  this.$toasted.error(error);
                  this.loading = false;
                });
          }
      });
    },
    createTrigger() {
      this.loading = true;
      this.record.triggers.push(this.$lodash.clone(this.default_trigger));
      this.$http
          .post(this.$store.getters.appUrl + "v2/" + this.page.name + "/" + this.record.id + "/triggers", this.$lodash.clone(this.default_trigger))
          .then(() => {
            this.loading = false;
            this.$toasted.success(this.$store.getters.translate("new_trigger_created"));
            this.load();
          })
          .catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toasted.error(error);
            }
            this.loading = false;
          });
    },
    saveTrigger(trigger) {
      this.loading = true;
      this.$http
          .patch(this.$store.getters.appUrl + "v2/" + this.page.name + "/" + this.record.id + "/triggers/" + trigger.id, trigger)
          .then(() => {
            this.loading = false;
            this.$toasted.success(this.$store.getters.translate("successfully_saved"));
            this.load();
          })
          .catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toasted.error(error);
            }
            this.loading = false;
          });
    },
    deleteTrigger(trigger) {
      if (!trigger) {
        return this.load();
      }
      this.loading = true;
      this.$http
          .delete(this.$store.getters.appUrl + "v2/" + this.page.name + "/" + this.record.id + "/triggers/" + trigger.id)
          .then(() => {
            this.loading = false;
            this.load();
          })
          .catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toasted.error(error);
            }
            this.loading = false;
          });
    },
    createAction() {
      this.loading = true;
      this.$http
          .post(this.$store.getters.appUrl + "v2/" + this.page.name + "/" + this.record.id + "/actions", this.$lodash.clone(this.default_action))
          .then(() => {
            this.loading = false;
            this.load();
            this.$toasted.success(this.$store.getters.translate("new_action_created"));
          })
          .catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toasted.error(error);
            }
            this.loading = false;
          });
    },
    saveAction(action) {
      this.loading = true;
      this.$http
          .patch(this.$store.getters.appUrl + "v2/" + this.page.name + "/" + this.record.id + "/actions/" + action.id, action)
          .then(() => {
            this.loading = false;
            this.$toasted.success(this.$store.getters.translate("successfully_saved"));
            this.load();
          })
          .catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toasted.error(error);
            }
            this.loading = false;
          });
    },
    deleteAction(action) {
      if (!action) {
        return this.load();
      }
      this.loading = true;
      this.$http
          .delete(this.$store.getters.appUrl + "v2/" + this.page.name + "/" + this.record.id + "/actions/" + action.id)
          .then(() => {
            this.loading = false;
            this.load();
          })
          .catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toasted.error(error);
            }
            this.loading = false;
          });
    },
    getFields(model_type, trigger_type) {
      this.loading = true;
      var url = "";
      let date_param = "";
      if (trigger_type === "before_date" || trigger_type === "after_date") {
        date_param = "&field_type=date";
      }
      url = this.$store.getters.appUrl + "v2/fields?model_type=" + model_type + date_param;
      this.$http
        .get(url)
        .then((response) => {
          let fields = [];
          response.data.forEach((field) => {
            if (field.name === "id" || field.name === "tags") {
              //IGNORE TAGS
            } else {
              fields.push({
                text: this.$store.getters.translate(field.name),
                value: field.name,
                available_options: field.available_options,
                field_type: field.field_type,
              });
            }
          });
          this.fields = this.$lodash.sortBy(fields, "text");
          this.loading = false;
        })
        .catch((error) => {
          if (this.$store.getters.isLoggedIn) {
            this.$toasted.error(error);
          }
          this.loading = false;
        });
    },
    addModels(objects) {
      objects.forEach((object) => {
        let new_object = {
          id: object.id,
          name: object.name,
        };
        this.active_action.models.push(new_object);
      });
    },
    getField(name) {
      return this.fields.find((field) => field.value === name);
    },
    getItems(name) {
      if (name === "country_of_birth_id") {
        return this.countries;
      } else if (name === "nationality_id") {
        return this.nationalities;
      } else {
        let available_options = this.fields.find((field) => field.value === name).available_options;
        let items = [];
        if (available_options) {
          available_options.forEach((option) => {
            items.push({
              text: this.$store.getters.translate(option),
              value: option,
            });
          });
        }
        return items;
      }
    },
    getCountryNationality() {
      this.$http
        .get(this.$store.getters.appUrl + "v2/countries")
        .then((response) => {
          this.countries = [];
          response.data.country.forEach((option) => {
            this.countries.push({
              value: option.country_id,
              text: option.country_name,
            });
          });
          this.nationalities = [];
          response.data.nationality.forEach((option) => {
            this.nationalities.push({
              value: option.nationality_id,
              text: option.nationality_name,
            });
          });
        })
        .catch((error) => {
          if (this.$store.getters.isLoggedIn) {
            this.$toasted.error(error);
          }
        });
    },
    deleteFile(file) {
      this.$confirm(this.$store.getters.translate("are_you_sure")).then((res) => {
          if (res) {
            this.loading = true;
            this.$http
              .delete(this.$store.getters.appUrl + "v2/files/" + file.id)
              .then(() => {
                this.loading = false;
                this.load();
                this.$toasted.success(this.$store.getters.translate("success"));
              })
              .catch((error) => {
                if (this.$store.getters.isLoggedIn) {
                  this.$toasted.error(error);
                }
                this.loading = false;
              });
          }
      });
    },
    saveFiles(action) {
      this.loading = true;
      const formData = new FormData();
      action.new_files.forEach((file) => {
        formData.append("attachment[]", file);
      });
      formData.append("action_id", action.id);
      this.$http
        .post(this.$store.getters.appUrl + "v2/actions/uploadfiles", formData, { headers: { "Content-Type": "multipart/form-data" }})
        .then(() => {
          this.loading = false;
          this.$toasted.success(this.$store.getters.translate("successfully_saved"));
          this.load();
        })
        .catch((error) => {
          if (this.$store.getters.isLoggedIn) {
            this.$toasted.error(error);
          }
          this.loading = false;
        });
    },
    download(file) {
      this.loading = true;
      this.$http
        .get(this.$store.getters.appUrl + "v2/files/" + file.id, { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data], { type: file.content_type });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = file.name;
          link.click();
          URL.revokeObjectURL(link.href);
          this.loading = false;
        })
        .catch((error) => {
          if (this.$store.getters.isLoggedIn) {
            this.$toasted.error(error);
          }
          this.loading = false;
        });
    },
    async getTemplates() {
      this.loading = true;
      this.$http
          .get(this.$store.getters.appUrl + "v2/templates")
          .then((response) => {
            this.templates = [];
            response.data.data.forEach((option) => {
              this.templates.push({
                id: option.id,
                name: option.name,
              });
            });
            this.loading = false;
          })
          .catch((error) => {
            this.$toasted.error(error);
            this.loading = false;
          });
    },
    modelRelationInput(val) {
      return this.models.find((model) => model.value === val).relation;
    },
    getActionIcon(type) {
      let action_type = this.action_types.find((action) => action.value === type);
      if (action_type) {
        return action_type.icon;
      }
      else {
        return null;
      }
    },
    preview(file) {
      this.$refs.document_edit.openModal('document_edit');
      this.$refs.document_edit.load(file);
    },
    getTriggerIcon(type) {
      let trigger_type = this.trigger_types.find((trigger) => trigger.value === type);
      if (trigger_type) {
        return trigger_type.icon;
      }
      else {
        return null;
      }
    },
    determineSubject(action) {
      if (action.type === "notify_current_user") {
        return "<p>" + action.message_nl + "</p>";
      }
      else if (action.type === "notify_users") {
        return "<p>" + action.message_nl + "</p>";
      }
      else if (action.type === "notify_roles") {
        return "<p>" + action.message_nl + "</p>";
      }
      else if (action.type === "notify_model") {
        return "<p>" + action.message_nl + "</p>";
      }
      else if (action.type === "attach_tag" || action.type === "remove_tag") {
        return "<p>" + action.message_nl + "</p>";
      }
      else if (action.type === "create_note") {
        return "<p>" + action.title + "</p>";
      }
      else if (action.type === "change_status") {
        return "<p>" + this.$store.getters.translate(action.parameters.change_status) + "</p>";
      }
      else {
        return "<p></p>";
      }
    },
  },
  computed: {
    modelOptions() {
      if (this.record.linked_model) {
        return [
          {
            value: this.record.model_type,
            text: this.$store.getters.translate(this.record.model_type),
          },
          {
            value: this.record.linked_model,
            text: this.$store.getters.translate(this.record.linked_model),
          },
        ];
      }
      else {
        return [
          {
            value: this.record.model_type,
            text: this.$store.getters.translate(this.record.model_type),
          },
        ];
      }
    },
    panel: {
      set(value) {
        let payload = {
          module_name: this.page.name,
          value: value,
        };
        this.$store.dispatch("setPanel", payload);
      },
      get() {
        return this.$store.getters.getPanel(this.page.name);
      },
    },
    models() {
      return models;
    },
    getEvents() {
      return this.record.model_type ? this.$lodash.sortBy(this.models.find((model) => model.value === this.record.model_type).children, "text") : [];
    },
    getTriggers() {
      if (this.record.event === "scheduler") {
        return [
          {
            value: "before_date",
            text: this.$store.getters.translate("before_date"),
          },
          {
            value: "after_date",
            text: this.$store.getters.translate("after_date"),
          },
          {
            value: "field_changes",
            text: this.$store.getters.translate("field_changes"),
          },
          {
            value: "added_tag",
            text: this.$store.getters.translate("added_tag"),
          },
          {
            value: "tag_exist",
            text: this.$store.getters.translate("tag_exist"),
          },
        ];
      } else {
        return [
          {
            value: "field_changes",
            text: this.$store.getters.translate("field_changes"),
          },
          {
            value: "added_tag",
            text: this.$store.getters.translate("added_tag"),
          },
          {
            value: "tag_exist",
            text: this.$store.getters.translate("tag_exist"),
          },
          {
            value: "certificate_not_exist",
            text: this.$store.getters.translate("certificate_not_exist"),
          },
        ];
      }
    },
    model() {
      return this.record.model_type;
    },
    modelRelation() {
      return this.models.find((model) => model.value === this.model).relation;
    },
    relatedModels() {
      return [
        related_models_tree.find((model) => model.id === this.record.model_type),
      ];
    },
    relatedModelsLinked() {
      return [
        related_models_tree.find((model) => model.id === this.record.linked_model),
      ];
    },
    getKindsForSelect() {
      return Object.entries(this.notes_kinds).map(([value, text]) => ({text, value}));
    },
  },
  watch: {
    model: {
      handler() {
        this.getTemplates();
        this.key = this.key + 1;
      },
    },
    active_action: {
      handler() {
        this.saveAction(this.active_action);
      },
      deep: true,
    },
  },
};
</script>
